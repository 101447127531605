import React, { useState } from 'react'
import { graphql } from 'gatsby'

// COMPONENTS:
import Intro from 'Components/home/Intro'
import MobileApps from 'Components/common/MobileApps'
import SalesArguments from 'Components/home/SalesArguments'
import ClientTestimonial from 'Components/home/ClientTestimonial'
import clientLogo from 'images/clients/clientlogos/client-nebbenes-2.jpg'
import Enticement from 'Components/home/Enticement'
import Video from 'Components/home/Video'
import Divider from 'Components/common/Divider'
import Clients from 'Components/common/Clients'
import { Modal, Button, Container, Row } from 'reactstrap'

export interface HomeType {
  [key: string]: {
    [key2: string]: string
  }
}

interface IProps {
  location: object
  data: {
    stringsJson: {
      home: {
        intro: {
          [key: string]: string
        }
        salesArguments: HomeType
        mobileApps: {
          [key: string]: string
        }
        clientTestimonial: {
          [key: string]: string
        }
        enticement: {
          header: string
          message: string
        }
        clients: {
          header: string
          languages: {
            [key2: string]: string
          }
          more: string
        }
        newsletterButton: string
      }
    }
  }
}

const HomePage: React.FC<IProps> = ({
  location,
  data: {
    stringsJson: { home },
  },
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleModal = () => setIsModalOpen(!isModalOpen)

  return (
    <>
      <Intro intro={home.intro} location={location} />
      <Video />
      <SalesArguments salesArgs={home.salesArguments} />
      <Container className="pt-6 d-flex justify-content-center">
        <Row>
          <Button
            className="newsletter-button newsletter-button--desktop"
            onClick={toggleModal}
          >
            {home.newsletterButton}
          </Button>
          <a
            href="https://eepurl.com/gICvkn"
            className="newsletter-button newsletter-button--mobile"
            target="_blank"
          >
            {home.newsletterButton}
          </a>
        </Row>
      </Container>
      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        style={{ width: '100%', minWidth: 600 }}
      >
        <iframe
          width="600"
          height="640"
          src="https://eepurl.com/gICvkn"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />
      </Modal>
      <MobileApps mobileApps={home.mobileApps} />
      <ClientTestimonial
        messageId={home.clientTestimonial.message}
        clientNameId={home.clientTestimonial.clientName}
        clientPositionId={home.clientTestimonial.clientPosition}
        companyImageUrl={clientLogo}
      />
      <Enticement
        headerId={home.enticement.header}
        messageId={home.enticement.message}
        noIntl={true}
      />
      <Divider />
      <Clients
        headerId={home.clients.header}
        usaId={home.clients.languages.usa}
        ukId={home.clients.languages.uk}
        noId={home.clients.languages.no}
        moreId={home.clients.more}
      />
    </>
  )
}

export default HomePage

export const query = graphql`
  query HomeExtendedQuery($lang: String!) {
    stringsJson(home: { lang: { eq: $lang } }) {
      ...Home
    }
  }
`
